.button-container {
  display: flex;
  justify-content: space-between;
  flex-flow: wrap-reverse;
  white-space: nowrap; /* Prevent line wrapping */
  overflow: none; /* Hide overflowing content if necessary */
}

#nextLink:hover #nextArrow {
  color: #7c6a21;
  margin-right: -0.1em;
}

#nextLink:hover span {
  color: #7c6a21;
}

#backLink:hover #backArrow {
  color: #7c6a21;
  margin-left: -0.1em;
}

#backLink:hover span {
  color: #7c6a21;
}

#nextLink,
#backLink {
  padding-inline: 1em;
}

.button-container > *:nth-child(2) {
  margin-left: auto;
  margin-right: 0;
}
