@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap");

* {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

main h1 {
  padding-bottom: 0.5em;
  font-weight: bold;
  text-align: center;
}

main h2,
main h3,
main h4 {
  font-weight: bold;
  padding-bottom: 0.5em;
  padding-top: 0.5em;
}

.popout {
  background-color: antiquewhite !important;
  filter: drop-shadow(0.2rem 0.2rem 0.2rem lightgray);
  margin-bottom: 1em;
}

.popout .card-title,
.examples .card-title {
  font-weight: bold;
  font-size: 1.6em;
}

.card-body .accordion {
  margin-block: -30px !important;
  margin-inline: -10px;
}

.examples {
  background-color: rgb(249, 236, 252) !important;
  margin-bottom: 1em;
  filter: drop-shadow(0.2rem 0.2rem 0.2rem lightgray);
}

.bodys {
  background-color: #fef9f8;
}

main {
  padding-bottom: 1em;
  min-height: min(calc(100vh - 132px), calc(94.3vh - 91px));
  overflow: none;
}

#root div div:first-child::-webkit-scrollbar {
  display: none;
}

.sideNav {
  height: 100%;
  background-color: #544e52;
  color: white;
}

.sideNav a {
  color: white;
  text-decoration: none;
}

.sideNav .l2 a {
  color: lightgray;
  transition: color 0.1s ease-in;
}

ul.sb {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}

.sb.inner:hover {
  background-color: #544e52;
  border-radius: 5px;
}

ul.inner li:hover {
  color: white;
  padding-left: 5px;
  transition: 0.1s ease-in;
}

.sideNav .l2:hover {
  color: white;
}

.sideNav .l1 {
  background-color: #423d40;
  border-bottom: 2px solid;
  border-bottom-color: #635c61;
  font-size: 20px;
  padding-block: 5px;
  padding-left: 20px;
}

.sideNav .l1:hover {
  color: lightgray;
  cursor: pointer;
}

.sideNav .l2 {
  background-color: #635c61;
  border-bottom: 2px solid;
  border-bottom-color: #544e52;
  font-size: 16px;
  padding-block: 5px;
  padding-left: 20px;
  padding-right: 10px;
  line-height: 20px;
  transition: background 0.2s ease-in;
}

.sideNav .l2:hover {
  background-color: #423d40;
  cursor: pointer;
}

.sideNav .l2 div {
  border-bottom: 0px;
  font-size: 16px;
  padding-left: 10px;
}

.sideNav .l2.closed * {
  display: none;
}

.showBar {
  height: auto;
  max-height: 1000px;
  overflow: hidden;
  transition: all 0.25s cubic-bezier(1, 0, 1, 0);
}

.hideBar {
  max-height: 0;
  overflow: hidden;
  transition: all 0.25s cubic-bezier(0, 1, 0, 1);
}

.accordion-button {
  background-color: transparent !important;
  z-index: 0 !important; /* below header */
  font-weight: bold;
}

.accordion-item {
  background-color: transparent !important;
}

header {
  z-index: 1 !important; /* above accordions */
}

form div:not(:first-child) .form-label {
  margin-top: 20px;
}

form div .form-label {
  font-weight: bold;
}

.form-label * {
  font-weight: normal;
}

.chevron {
  transition: 0.5s;
  float: right;
  margin-right: 10px;
  margin-top: 5px;
}

.chevron2 {
  transition: 0.5s;
  float: right;
  margin-right: 3px;
  margin-top: 2px;
}

.chevDown {
  rotate: 90deg;
}

.chevDown2 {
  rotate: 90deg;
}
